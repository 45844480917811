import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useMap } from 'react-leaflet';

// Fix for default marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const LocationList = ({ locations }) => {
  return (
    <div className="location-list">
      <h2>Locations</h2>
      <ul>
        {locations.map(location => (
          <li key={location.id}>
            <div>{location.address}</div><br />
            {location.description}<br />
            <a href={`/real_estate_assets/${location.id}`}>View</a>
            <hr></hr>
          </li>
        ))}
      </ul>
    </div>
  );
};

const FitBounds = ({ locations }) => {
  const map = useMap();

  useEffect(() => {
    if (locations.length > 0) {
      const bounds = locations.map(location => [location.latitude, location.longitude]);
      map.fitBounds(bounds);

      if (locations.length === 1) {
        map.setZoom(5);
      }
    }
  }, [locations, map]);

  return null;
};

const LeafletMap = () => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const scriptTag = document.getElementById("real_estate_assets");

    if (scriptTag) {
      const data = JSON.parse(scriptTag.textContent.trim());
      const locations = data.map((item) => ({
        id: item.id,
        description: item.details["description"],
        address: item.address,
        latitude: item.latitude,
        longitude: item.longitude,
      }));
      setLocations(locations);
    }
  }, []);

  if (locations.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="map-and-list">
      <div className="map-container">
        <MapContainer center={[locations[0].latitude, locations[0].longitude]} style={{ height: "1000px", width: "1000px" }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {locations.map(location => (
            <Marker key={location.id} position={[location.latitude, location.longitude]}>
              <Popup>
                {location.name} <br /> {location.address}
              </Popup>
            </Marker>
          ))}
          <FitBounds locations={locations} />
        </MapContainer>
        </div>
      <div className="list-container">
        <LocationList locations={locations} />
      </div>
    </div>
  );
};

export default LeafletMap;
