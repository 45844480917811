import React from "react";
import { createRoot } from "react-dom/client";
//import Calendar from "./calendar";
import LeafletMap from "./LeafletMap";

export default function App() {
  //return <Calendar />;
  return <LeafletMap/>;
}

document.addEventListener("turbo:load", () => {
  const app = document.getElementById("app");

  if (app) {
    const root = createRoot(app);
    root.render(<App />);

    document.addEventListener("turbo:before-visit", () => {
      root.unmount();
    });
  }
});